import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";

const PAGINATION_QUERY = graphql`
  query GET_PAGINATION_ITEMS {
    wpgraphql {
      cpt_properties {
        nodes {
          id
          acf_properties {
            nextLink {
              ... on WPGraphQL_Cpt_property {
                title
                slug
              }
            }
            previousLink {
              ... on WPGraphQL_Cpt_property {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;

const PortfolioPagination = props => (
  <StaticQuery
    query={PAGINATION_QUERY}
    render={pages => {
      return (
        <section className="section__pagination">
          {pages.wpgraphql.cpt_properties.nodes.map((node, i) => (
            <div>
              {node.id === props.propid && (
                <nav aria-label="Property navigation">
                  <ul className="pagination justify-content-center">
                    {node.acf_properties.previousLink != null && (
                      <li className="page-item">
                        <Link
                          className="page-link text-right page-link-left"
                          to={`/portfolio/${node.acf_properties.previousLink.slug}`}
                        >
                            <em className="d-sm-none">Prev</em>
                            <em className="d-none d-sm-block">Previous</em>
                          <span className="d-none d-sm-block">

                            {node.acf_properties.previousLink.title}
                          </span>

                        </Link>
                      </li>
                    )}
                    <li className="page-item-center text-center">
                      <Link to="/portfolio">

                        Back to
                        <br />
                        Portfolio
                      </Link>
                    </li>
                    {node.acf_properties.nextLink != null && (
                      <li className="page-item">
                        <Link
                          className="page-link page-link-right"
                          to={`/portfolio/${node.acf_properties.nextLink.slug}`}
                        >
                            <em>Next</em>
                          <span className="d-none d-sm-block">

                            {node.acf_properties.nextLink.title}
                          </span>

                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </div>
          ))}
        </section>
      );
    }}
  />
);

export default PortfolioPagination;
